import * as React from 'react';
import styled from 'styled-components/macro';
import IconsJuniorButtonPlay from '../icons/IconsJuniorButtonPlay';
import { colorsV2 } from 'style/colors-v2';
import cl from 'classnames';

const CardVideoMain = styled.div`
  text-align: left;
  display: grid;
  gap: 12px;
  width: 100%;
  flex-shrink: 0;
  aspect-ratio: 130/233;
  max-width: 130px;

  .video-box {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    border-radius: 8px;
    overflow: hidden;

    .video-wrapper {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: grid;
      place-items: center;
      transition: 0.2s all;
      cursor: pointer;

      &:active {
        .play-button {
          transform: scale(0.9);
        }
      }

      img {
        width: 100%;
        height: 100%;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .play-button {
        transition: 0.2s all;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(3.72727px);
        width: 64px;
        min-width: 64px;
        height: 64px;
        min-height: 64px;
        border-radius: 50%;
        display: grid;
        place-items: center;
        font-size: 30px;
        color: ${colorsV2.white100};
      }

      > * {
        pointer-events: none;
      }

      &:hover {
        .play-button {
          color: ${colorsV2.blue60};
        }
      }
    }
  }
`;

export const CardVideo = ({ data, onPlayVideo }) => {
  const { title, thumbnailUrl } = data;
  return (
    <CardVideoMain className={cl('card-video')}>
      <div
        onClick={e => {
          e.preventDefault();
          onPlayVideo();
        }}
        className="video-box"
      >
        <div className="video-wrapper">
          <div className="overlay">
            <div className="play-button">
              <IconsJuniorButtonPlay />
            </div>
          </div>
          <img alt={title} src={thumbnailUrl} />
        </div>
      </div>
    </CardVideoMain>
  );
};
